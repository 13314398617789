let type = 'pro';
const url = type === 'dev' ? `http://localhost:5000/api/` : `https://dev.agentsgateway.com/api/`
const redirectURl = type === 'dev' ? 'http://localhost:3000' : 'https://dev.agentsgateway.com';
export const mainUrl = type === 'dev' ? 'http://localhost:3001' : 'https://dev.agentsgateway.com';

export const API_URL = {
    BucketURl: `https://dev.agentgateway.s3.us-east-2.amazonaws.com/`,
    loginUser: `${url}user/loginUser`,
    getAgentDevelopment: `${url}agent/getAgentDevelopment`,
    getAssignedDevelopment: `${url}agent/getAssignedDevelopment`,
    getSearchResult: `${url}agent/getSearchResult`,
    getRole: `${url}user/getrole`,
    getDevelopmentDetails: `${url}agent/getDevelopmentDetails`,
    checkToken: `${url}user/checkIfTokenHasExpired?token`,
    getDevelopmentUnitDetails: `${url}agent/getDevelopmentUnitDetails`,
    updateOneUnitStatusDevelopment: `${url}development/updateOneUnitStatusDevelopment`,
    getHtmlResult: `${url}calculations/getHtmlResult`,
    getOneCalculation: `${url}calculations/getOneCalculation`,
    getAllCalculations: `${url}calculations/getAllCalculations`,
    sendShareMail: `${url}agent/shareEmail`,
    sendResetMail: `${url}user/sendResetMail`,
    sendPrintData: `${url}agent/sendPrintData`,
    setpassword: `${url}user/setpassword`,
    getNotification: `${url}message/getMessage`,
    updateNotification: `${url}message/updateLastMessageRead`,
    shareMultiUnit: `${url}agent/shareMultiUnit`,
    printMultiUnit: `${url}agent/printMultipleUnit`,
    shareInvestment: `${url}agent/shareInvestment`,
    addParkingSpaceUnit: `${url}agent/addParkingSpaceUnit`,
    redirectURl: redirectURl
}